import React from 'react'
import Layout from '../components/Layout'
import styled from 'styled-components'

const Wrap = styled.div`
  padding: 4rem;
`;
const NotFoundPage = () => (
  <Layout>
    <Wrap>
      <h1>NOT FOUND</h1>
      <p>Sorry that page doesn't Exist.</p>
    </Wrap>
  </Layout>
)

export default NotFoundPage
